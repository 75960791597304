<template>
  <div id="bg">
    <div class="search">
      <div class="search-box">
        <el-date-picker v-model="searchDate" type="datetimerange" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" value-format="YYYY-MM-DD HH:mm:ss"></el-date-picker>
      </div>
      <div class="search-box">
        帖子ID:
        <el-input type="text" class="width_150" placeholder="请输入帖子ID" v-model="search.id"></el-input>
      </div>
      <div class="search_box">
        状态:<el-select class="width_100" v-model="search.status">
        <el-option  :value=-1 label="全部"></el-option>
        <el-option  :value=0 label="审核中"></el-option>
        <el-option  :value=1 label="审核通过"></el-option>
        <el-option  :value=2 label="审核拒绝"></el-option>
      </el-select>
      </div>
        <div class="search_box">
          官方:<el-select class="width_80" v-model="search.is_gw">
          <el-option  :value=-1 label="全部"></el-option>
          <el-option  :value=0 label="非官方"></el-option>
          <el-option  :value=1 label="官方"></el-option>
        </el-select>
        </div>
      <div class="search-box">
        <el-button type="primary" size="medium" @click="getList()">查询</el-button>
      </div>
      <div class="search-box">
        <el-button type="primary" size="medium" @click="multiDel()">删除</el-button>
      </div>
      <div class="search-box">
        <el-button type="primary" size="medium" @click="multiStatus(1)">审核通过</el-button>
      </div>
      <div class="search-box">
        <el-button type="primary" size="medium" @click="multiStatus(2)">审核拒绝</el-button>
      </div>
      <div class="search-box">
        <el-button type="primary" size="medium" @click="showAdd()">发帖</el-button>

      </div>
    </div>
    <el-table :data="tableData" @selection-change="selectionChange" :header-cell-style="{background:'#F7F8FA'}">
      <el-table-column type="selection" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="id" label="ID" align="center" :show-overflow-tooltip="true"></el-table-column>
<!--      <el-table-column prop="post_uid" label="用户ID" align="center" :show-overflow-tooltip="true"></el-table-column>-->
      <el-table-column prop="account" label="用户账号" align="center" :show-overflow-tooltip="true"></el-table-column>
<!--      <el-table-column prop="ip" label="IP" align="center" :show-overflow-tooltip="true"></el-table-column>-->
      <el-table-column prop="title" label="标题" align="center" :show-overflow-tooltip="true"></el-table-column>
<!--      <el-table-column prop="content" label="内容" align="center" :show-overflow-tooltip="true"></el-table-column>-->
      <el-table-column prop="tags" label="标签" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          {{getTagsTitle(scope.row.tags)}}
        </template>
      </el-table-column>
      <el-table-column prop="type" label="类型" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <span  v-if="scope.row.type == 0">纯文本</span>
          <span  v-if="scope.row.type == 1">图片</span>
          <span v-if="scope.row.type == 2">视频</span>
        </template>
      </el-table-column>
      <el-table-column prop="tips" label="小费" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="discuss_count" label="评论数" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="view_number" label="浏览量" align="center" :show-woverflow-tooltip="true"></el-table-column>
      <el-table-column prop="add_time" label="发帖时间" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="enable" label="状态" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <span style="color: gray" v-if="scope.row.status == 0">审核中</span>
          <span style="color:green" v-if="scope.row.status == 1">审核通过</span>
          <span style="color: red" v-if="scope.row.status == 2">审核拒绝</span>
        </template>
      </el-table-column>
      <el-table-column prop="weight" label="置顶" align="center" :show-overflow-tooltip="true">
      <template #default="scope">
        <span v-if="scope.row.weight > 0" style="color:green">已置顶</span>
      </template>
      </el-table-column>
      <el-table-column prop="is_gw" label="官方" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <span v-if="scope.row.is_gw > 0" style="color:green">是</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="300px">
        <template #default="scope">
          <span class="operation"  v-if="scope.row.status == 1 && scope.row.weight > 0" @click="zhiding(scope.row)">取消置顶</span>
          <span class="operation"  v-if="scope.row.status == 1 && scope.row.weight == 0" @click="zhiding(scope.row)">置顶</span>
          <span class="operation"  v-if="scope.row.status == 1 " @click="showTuijian(scope.row)">后台推荐</span>
          <span class="operation"  v-if="scope.row.status == 0 " @click="showAddDialog(scope.row)">审核</span>
          <span class="operation" @click="showAdd(scope.row)">编辑</span>
          <span class="operation" @click="delRow(scope.$index,scope.row)">删除</span>
        </template>
      </el-table-column>
    </el-table>
    <page :pager="pager" @query="getList()" />
   <el-dialog title="后台推荐" v-model="tuijianVisible" width="500px" height="200px" top="5vh" :close-on-click-modal="false" >
      <el-row>
        <el-col :span="4" align="right">ID:</el-col>
        <el-col :span="20" >{{tuijianArray.id}}</el-col>
      </el-row>
     <el-row>
       <el-col :span="4" align="right">点赞量:</el-col>
       <el-col :span="20" >
         <el-input v-model="tuijianArray.goods_count" placeholder="请输入点赞数量!"></el-input>
       </el-col>
     </el-row>
     <el-row>
       <el-col :span="4" align="right"></el-col>
       <el-col :span="20" >
         <el-button @click="tuijianVisible=false">取消</el-button>
         <el-button type="primary" @click="tuijian">保存</el-button>
       </el-col>
     </el-row>
      <div style="height: 20px;"></div>
    </el-dialog>
    <el-dialog :title="addtitle" v-model="addVisible" width="1000px" height="600px" top="5vh" :close-on-click-modal="false" >
      <el-row>
        <el-col :span="4" align="right">账号:</el-col>
        <el-col :span="20" >
          <el-input v-model="add.username" v-if="row==undefined" placeholder="请输入账号!"></el-input>
          <el-input v-model="add.username" v-if="row!=undefined" disabled placeholder="请输入账号!"></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4" align="right">标题:</el-col>
        <el-col :span="20" >
          <el-input v-model="add.title" placeholder="请输入标题"></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4" align="right">标签:</el-col>
        <el-col :span="20" >
          <el-select style="width: 100%" clearable v-model="add.tags" multiple multiple-limit=3>
            <el-option v-for="item in Tags" :key="item" :label="item.title" :value="item.id"></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4" align="right">内容:</el-col>
        <el-col :span="20" >
          <fuwenben v-model="add.content" :isClear="isClear" :desc="add.content" @itemclick="addclick"></fuwenben>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4" align="right">类型:</el-col>
        <el-col :span="20" >
          <el-select v-model="add.type">
            <el-option :value=0 label="纯文本"></el-option>
            <el-option :value=1 label="图片"></el-option>
            <el-option :value=2 label="视频"></el-option>
          </el-select>

          官方活动: <el-checkbox v-model="add.is_gw" :true-label="1" :false-label="0"></el-checkbox>
        </el-col>
      </el-row>
      <el-row       v-if="add.type==1">
        <input type="file" ref="chapterUpload" style="display: none" multiple @change="handlePostImages" accept="image/jpeg,image/png,image/webp,image/gif">
        <el-col :span="4" align="right"><el-button @click="selectUpload" class="el-icon-upload" type="primary">选择图片</el-button><br><br><el-button @click="startUpload">开始上传</el-button></el-col>
        <el-col :span="20" >
          <div class="upload_image_div">
            <div class="upload_image_list" v-for="(item,index) in postImages.list" :key="item" >
              <ximg class="upload_image_item" :width="200" :height="100" :src="item.url" alt="点击查看大图"></ximg>
              <i class="el-icon-success success" v-if="item.status==1"></i>
              <i class="el-icon-delete delete" @click="uploadImageDelete(index)"></i>
              <el-progress v-if="item.percentage > 0" class="progress" :percentage="item.percentage" :stroke-width="14" :text-inside="true" ></el-progress>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row v-if="add.type==2">
        <el-col :span="4" align="right"><el-upload
        action="#"
        :http-request="videoUpload"
        :show-file-list="false"
        accept="video/mp4"
        >
          <el-button size="small">视频上传</el-button>
        </el-upload></el-col>
        <el-col :span="20" >
            <div class="showVideo">
              <div id="video">
              </div>
              <el-progress v-if="videoPercent > 0" class="progress" type="circle" width="200" :percentage="videoPercent" ></el-progress>
            </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4" align="right"></el-col>
        <el-col :span="20" >
          <el-button @click="addVisible=false">取消</el-button>
          <el-button type="primary" @click="addPost" v-if="row == undefined">发帖</el-button>
          <el-button type="primary" @click="addPost" v-if="row != undefined">保存</el-button>
        </el-col>
      </el-row>
      <div style="height: 20px;"></div>
    </el-dialog>

    <el-dialog title="帖子详情" v-model="editVisible" width="1000px" height="600px" top="5vh" >
      <el-row>
        <el-col :span="4" align="right">账号:</el-col>
        <el-col :span="20" >
          <el-input v-model="edit.username" disabled placeholder="请输入账号!"></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4" align="right">标题:</el-col>
        <el-col :span="20" >
          <el-input v-model="edit.title" placeholder="请输入标题!"></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4" align="right">标签:</el-col>
        <el-col :span="20" >
          <el-select style="width: 100%" clearable v-model="edit.tags" multiple >
            <el-option v-for="item in Tags" :key="item" :label="item.title" :value="item.id"></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4" align="right">内容:</el-col>
        <el-col :span="20" >
          <el-input type="textarea" :autosize="{ minRows: 10, maxRows: 20}" v-model="edit.content" placeholder="请输入帖子内容!" ></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4" align="right">类型:</el-col>
        <el-col :span="20" >
          <el-select v-model="edit.type" disabled>
            <el-option :value=0 label="纯文本"></el-option>
            <el-option :value=1 label="图片"></el-option>
            <el-option :value=2 label="视频"></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row v-if="edit.type == 1">
        <el-col :span="4" align="right">图片列表</el-col>
        <el-col :span="20" >
          <div class="upload_image_div">
            <div class="upload_image_list" v-for="(item) in postImages.list" :key="item" >
              <ximg  class="upload_image_item" :width="200" :height="100" :src="item.url" alt=""></ximg>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row v-show="row.type == 2">
        <el-col :span="4" align="right">
          视频预览:</el-col>
        <el-col :span="20" >
          <div class="showVideo">
            <div id="video-edit"  v-html="edit.videoEditHtml">
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4" align="right">状态:</el-col>
        <el-col :span="20" >
          <span style="color: gray" v-if="row.status == 0">审核中</span>
          <span style="color:green" v-if="row.status == 1">审核通过</span>
          <span style="color: red" v-if="row.status == 2">审核拒绝</span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4" align="right"></el-col>
        <el-col :span="20" >
          <el-button @click="closeDetail()">关闭</el-button>
          <el-button v-if="row.status == 0" @click="setStatus(row,1)">通过</el-button>
          <el-button  v-if="row.status == 0" @click="setStatus(row,2)">拒绝</el-button>
        </el-col>
      </el-row>
      <div style="height: 20px;"></div>
    </el-dialog>
  </div>
</template>

<script>
import page from "@/components/page";
import httpClient from "@/config/httpClient";
import fuwenben from "@/components/wangEditor";
// import videoJs from 'video.js';
// import "videojs-contrib-hls"
// import "video.js/dist/video-js.css"
export default {
  name: "community_post",
  components: {
    page,
    fuwenben
  },
  data(){
    return {
      pager:{page:1,rows:50,total:0},
      search:{
        id:null,
        post_id:"",
        content:"",
        status:-1,
        is_gw:-1,
        checked:0,
      },
      searchDate:null,
      tableData:[],
      selectionRows:[],
      tuijianVisible:false,
      tuijianArray:{
        id:null,
        goods_count:0,
        row:null,
      },
      addVisible:false,
      editVisible:false,
      addtitle:"发帖",
      isClear: false,
      add:{
        content:"",
        username:"",
        tags:[],
        type:0,
        video_url:"",
        is_gw:0,
      },
      edit:{
        content:"",
        username:"",
        tags:[],
        type:0,
        video_url:"",
        videoEditHtml:"",
        player:null
      },
      Tags:[], //标签
      postImages:{
        list:[
          // {url:"aaaa",status:0,percentage:0},
          // {url:"aaaa",status:0,percentage:50},
          // {url:"aaaa",status:1,percentage:100}
        ],
      },
      size_1m:1024 *1024,
      row:null,
      max_duration: 360, // 视频最大秒数
      videoPercent:0, // 视频上传百分比
      compress_height:0, // 压缩尺寸
      compress_width:0, // 压缩尺寸
      videoLoading:false,
    }
  },
  mounted() {
    this.getTags();
    this.getList()
  },
  methods:{
    getTagsTitle(tags){
      if (!tags){
        return ""
      }
      var title = []
      for(const tag of tags){
        title.push(tag.title)
      }
      return title.join(",")
    },
    addclick(item) {
      this.add.content = item;
    },
    closeDetail(){
      this.edit.videoEditHtml = ""
      // if (this.edit.player){
      //   this.edit.player.dispose()
      // }
      this.editVisible = false
    },
    showBigImage(list,index){
      this.$common.showBigImages(list,index)
    },
    zhiding(row){
      httpClient("UpWeight").post({id:row.id}).then((res)=>{
        if (res.code == 0){
          this.$message({message:"置顶成功",type:"success"})
          this.getList()
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      },(err)=>{
        console.log(err)
      })
    },
    videoUpload(param){
      this.videoPercent = 0
      document.getElementById("video").innerHTML = ""
      if (param.file.size > this.size_1m * 100){
        self.$message({message:"视频不能超过100M",type:"error"})
        return;
      }
      var videoElement = document.createElement("video")
      // videoElement.id = "community-video-element"
      videoElement.width = 200;
      videoElement.autoplay = true
      videoElement.loop = true
      videoElement.muted = true
      videoElement.controls = true
      videoElement.style.display = "none"
      videoElement.src = URL.createObjectURL(param.file);
      this.videoLoading = true
      var self = this
      videoElement.addEventListener("canplay",function (e){
        if (parseInt(e.target.duration) > self.max_duration){
          this.videoLoading = false
          self.$message({type:"error",message:"视频不能超过"+self.max_duration+"秒!"})
          return;
        }
        // 开始上传
        self.startUploadVideo(param.file,()=>{
          videoElement.style.display = "block"
        })
        self.uploadVideoImage(videoElement)
      })
      document.getElementById("video").appendChild(videoElement)
    },
    uploadVideoImage(videoElement){
      this.postImages.list = [] // 重置一下
      // 开始截图
      var canvas = document.createElement("canvas")
      canvas.width = videoElement.videoWidth;
      canvas.height = videoElement.videoHeight
      canvas.getContext("2d").drawImage(videoElement , 0, 0, canvas.width, canvas.height);
      var url = canvas.toDataURL("image/png"); // 生成图片
      var file = this.base64ToFile(url,(new Date()).getTime()+".png")
      var _headers = {
        "is_compress":"true",
        "image_width":this.compress_width,
        "image_height":this.compress_height,
        'Name':"community",
        "is-encode":"true",

      }
      httpClient().setHeader(_headers).upload(file).then((res)=>{
        if(res.code == 0){
          this.postImages.list.push({
            compress_img:res.data.thumbnail, // 压缩图
            filename:res.data.filename, // 大图
          })
        }else{
          self.$message({type:"error",message:"视频封面图上传失败:"+res.msg})
        }
      })
    },
    base64ToFile(urldata,filename){
      let arr = urldata.split(",");
      let mine = arr[0].match(/:(.*?);/)[1];
      let bytes = atob(arr[1]);// 解码base64
      let n = bytes.length
      let ia = new Uint8Array(n);
      while (n--){
        ia[n] = bytes.charCodeAt(n);
      }
      return new File([ia],filename,{type:mine})
    },
    startUploadVideo(file,callback){
      var _headers = {
        'Name':"community",
        "save":"true",
        "is-ffmpeg":"true",
      }
      this.videoPercent = 0
      httpClient().setHeader(_headers).uploadVideoSlice(file, (percentage)=>{
        this.videoPercent = percentage
      },(res)=>{
        this.add.video_url = res.filename
        this.$message({message:"上传成功",type:"success"})
        this.videoPercent = 0
        this.videoLoading = false
        callback()
        // 服务器解压，然后导入数据库
      },(err)=>{
        console.log(err)
        this.videoLoading = false
      })
    },
    showAddDialog(row){
      console.log(row)
      this.row = row
      var tags = []
      if (row.tags){
        for (const tag of row.tags){
          tags.push(tag.id)
        }
      }
      this.edit.content = row.content
      this.edit.title = row.title
      this.edit.videoEditHtml = ""
      if(this.edit.player!= null){
        this.edit.player.dispose()
      }
      this.edit.username = row.account
      this.edit.tags = tags
      this.edit.type = row.type
      this.postImages.list = [];
      if (row.type == 1){
        if (row.images != ""){
          for(const img of row.images_){
            this.postImages.list.push({
              url:img
            })
          }
        }
      }
      this.editVisible = true
      if (row.type == 2){
        this.$nextTick(()=>{
          document.getElementById("video-edit").innerHTML = ""
          var videoElement = document.createElement("video")
          videoElement.width = 200;
          videoElement.autoplay = true
          videoElement.loop = true
          videoElement.muted = true
          videoElement.controls = true
          videoElement.src = this.$store.getVideo(row.play_url)
          document.getElementById("video-edit").appendChild(videoElement)
        })
      }
    },
    editPost(){
      if(this.add.content == "" && this.postImages.list.length == 0 && this.add.video_url == ""){
        this.$message({message:"请输入帖子内容!",type:"error"})
        return;
      }
      if (this.add.type == 1 && this.postImages.list.length == 0){
        this.add.type = 0
      }
      var images,big_images = "";
      if (this.postImages.list.length > 0){
        // 读取大图小图上传服务器
        var img = []
        var big_img = []
        for(const item of this.postImages.list){
          img.push(item.compress_img)
          big_img.push(item.filename)
        }
        images = img.join(",")
        big_images = big_img.join(",")
      }
      if (this.add.type == 1 || this.add.type == 0){
        this.add.video_url = ""
      }
      if (this.add.type == 2 && this.videoLoading){
        this.$message({message:"视频上传中,请稍后!",type:"error"})
        return;
      }
      if (this.add.type == 2 && this.add.video_url == ""){
        this.$message({message:"请选择视频上传!",type:"error"})
        return;
      }
      httpClient("EditPost").post({
        content:this.add.content,
        title:this.add.title,
        is_gw:this.add.is_gw,
        id:this.row.id,
        images:images,
        big_images:big_images,
        video_url:this.add.video_url,
        type:this.add.type,
        tags:this.add.tags.join(",")
      }).then((res)=>{
        if(res.code == 0){
          this.getList()
          // this.row.content = this.add.content
          // this.row.video_url = this.add.video_url
          // this.row.type = this.add.type
          // this.row.tags = this.add.tags
          this.addVisible = false
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      },(err)=>{
        console.log(err)
      })
    },
    addPost(){
      // console.log(this.add)
      if (this.row != undefined){
        // 编辑
        this.editPost()
        return;
      }

      if(this.add.username == ""){
        this.$message({message:"请输入帖子账号!",type:"error"})
        return;
      }
      if(this.add.content == "" && this.postImages.list.length == 0 && this.add.video_url == ""){
        this.$message({message:"请输入帖子内容!",type:"error"})
        return;
      }
      if (this.add.type == 1 && this.postImages.list.length == 0){
        this.add.type = 0
      }
      var images,big_images = "";
      if (this.postImages.list.length > 0){
        // 读取大图小图上传服务器
        var img = []
        var big_img = []
        for(const item of this.postImages.list){
          img.push(item.compress_img)
          big_img.push(item.filename)
        }
        images = img.join(",")
        big_images = big_img.join(",")
      }
      // if (this.add.type == 2 || this.add.type == 0){
      //   big_images = ""
      //   images = ""
      // }
      if (this.add.type == 1 || this.add.type == 0){
        this.add.video_url = ""
      }
      if (this.add.type == 2 && this.videoLoading){
        this.$message({message:"视频上传中,请稍后!",type:"error"})
        return;
      }
      if (this.add.type == 2 && this.add.video_url == ""){
        this.$message({message:"请选择视频上传!",type:"error"})
        return;
      }
      httpClient("AddPost").post({
        title:this.add.title,
        content:this.add.content,
        account:this.add.username,
        is_gw:this.add.is_gw,
        images:images,
        big_images:big_images,
        video_url:this.add.video_url,
        type:this.add.type,
        tags:this.add.tags.join(",")
      }).then((res)=>{
        if(res.code == 0){
          this.getList()
          this.addVisible = false
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      },(err)=>{
        console.log(err)
      })
    },
    startUpload(){
      // 开始上传
      if (this.postImages.list.length > 9){
        this.$message({message:"最多选择9个文件,请重新选择!",type:"error"})
        return;
      }
      for (const item of this.postImages.list){
        if (item.status == 1){
          continue
        }
        this.uploadImage(item)
      }
    },
    uploadImage(item){
      var _headers = {
        "is-compress":"true",
        "is_compress":"true",
        "is-encode":"true",
        "image_width":this.compress_width,
        "image_height":this.compress_height,
        'Name':"community"
      }
      httpClient().setHeader(_headers).setProgress(function(event){
        item.percentage = Math.floor(event.loaded / event.total) >= 99?99:Math.floor(event.loaded / event.total)
      }).upload(item.file).then((res)=>{
        if (res.code == 0){
          item.percentage = 100
          item.status = 1
          item.filename = res.data.filename
          item.compress_img = res.data.thumbnail==""? res.data.filename: res.data.thumbnail
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      })
    },
    uploadImageDelete(index){
      this.postImages.list.splice(index,1)
    },
    selectUpload(){
      this.$refs.chapterUpload.click()
    },
    handlePostImages(event){
      // 图片上传处理
      if (this.postImages.list.length + event.target.files.length > 9){
        this.$message({message:"最多选择9个文件,请重新选择!",type:"error"})
        return
      }
      for(const file of event.target.files){
        // 不允许大文件
        if (file.size > 30 * this.size_1m){
          this.$message({message:"文件"+file.name+"超过单文件最大允许大小,请重新选择!",type:"error"})
          return;
        }
      }
      for(const file of event.target.files){
        // 不允许大文件
        this.postImages.list.push({
          name:file.name,
          url:URL.createObjectURL(file),
          status:0,// 1表示上传成功
          percentage: 0, // 上传进度
          filename:"", // 上传成功的地址
          bigImage:"", // 大图
          file:file,
        })
      }

    },
    getTags(){
      httpClient("getTags").post({type:3}).then((res)=>{
        if (res.code == 0){
          this.Tags = res.data
        }else{
          this.$message({message:"读取帖子标签失败:"+res.msg,type:"error"})
        }
      },(err)=>{
        console.log(err)
      })
    },
    showAdd(row){
      this.row = row
      this.postImages.list = [];
      this.addtitle = "发帖"
      this.add = {
        content:"",
        title:"",
        username:"",
        tags:[],
        type:0,
        video_url:"",
        is_gw: 0,
      }
      if(row){
        this.addtitle = "编辑帖子"
        var tags = []
        if(row.tags){
          for (const tag of row.tags){
            tags.push(tag.id)
          }
        }
        this.add.title = row.title
        this.add.content = row.content
        this.add.videoEditHtml = ""
        this.add.username = row.account
        this.add.tags = tags
        this.add.type = row.type
        this.add.is_gw = row.is_gw
        this.add.video_url = row.video_url
        if (row.type == 1){
          if (row.images != ""){
            for(const img of row.images_){
              this.postImages.list.push({
                url:this.$store.getImage(img)
              })
            }
          }
        }
        if (row.type == 2 && row.video_url != ""){
          let _this = this
          this.$nextTick(()=>{
            document.getElementById("video").innerHTML = ""
            var videoElement = document.createElement("video")
            videoElement.width = 200;
            videoElement.autoplay = true
            videoElement.loop = true
            videoElement.muted = true
            videoElement.controls = true
            videoElement.src = _this.$store.getVideo(row.play_url)
            document.getElementById("video").appendChild(videoElement)
          })
        }
      }
      this.addVisible = true
    },
    showTuijian(row){
      this.tuijianArray.row = row
      this.tuijianArray.id = row.id
      this.tuijianArray.goods_count = row.goods_count
      this.tuijianVisible = true
    },
    tuijian(){
      httpClient("BackUp").post({
        id:this.tuijianArray.id,
        goods_count:this.tuijianArray.goods_count,
      }).then((res)=>{
        if (res.code == 0){
          this.tuijianArray.row.goods_count = this.tuijianArray.goods_count
          this.tuijianVisible = false
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      },(err)=>{
        console.log(err)
      })
    },
    setStatus(row,status){
      httpClient("BatchVerify").post({
        ids:row.id,
        status:status
      }).then((res)=>{
        if (res.code == 0){
            row.status = status
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      },(err)=>{
        console.log(err)
      })
    },
    multiStatus(status){
      if (this.selectionRows.length == 0){
        this.$message({message:"请选择要审核的帖子!",type:"error"})
        return
      }
      var ids = []
      for(const row of this.selectionRows){
        if (row.status == status){
          continue
        }
        ids.push(row.id)
      }
      if (ids.length == 0){
        return;
      }
      this.$confirm("批量审核"+(status == 1?"通过":"拒绝")+"帖子:" + ids.join(",") + "吗？").then(()=>{
        httpClient("BatchVerify").post({
          ids:ids.join(","),
          status:status
        }).then((res)=>{
          if (res.code == 0){
            for(const row of this.selectionRows){
              row.status = status
            }
          }else{
            this.$message({message:res.msg,type:"error"})
          }
        },(err)=>{
          console.log(err)
        })
      })
    },
    delRow(index,row){
      this.$confirm("删除帖子:" + row.id + "吗？").then(()=>{
            httpClient("DelPosts").post({
              ids: row.id
            }).then((res)=>{
              if (res.code == 0){
                this.tableData.splice(index,1)
              }else{
                this.$message({message:res.msg,type:"error"})
              }
            },(err)=>{
              console.log(err)
            })
      })
    },
    multiDel(){
      if (this.selectionRows.length == 0){
        this.$message({message:"请选择要删除的帖子!",type:"error"})
        return
      }
      var ids = []
      for(const row of this.selectionRows){
        ids.push(row.id)
      }
      this.$confirm("批量删除帖子:" + ids.join(",") + "吗？").then(()=>{
        httpClient("DelPosts").post({
          ids:ids.join(",")
        }).then((res)=>{
          if (res.code == 0){
            this.getList()
          }else{
            this.$message({message:res.msg,type:"error"})
          }
        },(err)=>{
          console.log(err)
        })
      })
    },
    selectionChange(rows){
      this.selectionRows = rows
    },
    getList(){
      var params = {
        page:this.pager.page,
        row:this.pager.rows,
        post_uid:this.search.post_id,
        is_gw:this.search.is_gw,
        id:this.search.id,
        // discuss_uid:this.search.discuss_uid,
        content:this.search.content,
        checked:this.search.checked,
        status:this.search.status,
        begin_time:this.searchDate && this.searchDate.length > 1?this.searchDate[0]:"",
        end_time:this.searchDate && this.searchDate.length > 1?this.searchDate[1]:"",
      }
      this.$common.showLoading()
      httpClient("GetPosts").post(params).then((res)=>{
        this.$common.hideLoading()
        if (res.code==0){
          this.tableData = res.data.list
          if (this.pager.page == 1 ){
            this.pager.total = res.data.total
          }
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      },(err)=>{
        this.$common.hideLoading()
        console.log(err)
      })
    },
  }
}
</script>

<style scoped>
.upload_image_div{
   display: flex; flex-direction: row; flex-wrap: wrap
}
.upload_image_list{
  width: 200px; height: 100px; position: relative; margin-right: 10px;
}
.upload_image_list .progress{
  /*display: none;*/
  width: 100%; height: 20px;
  position: absolute; bottom: 0;left: 0;
}
.upload_image_list .delete{
  display: none;
  position: absolute; cursor:pointer;top: 0;right: 0; width: 20px;background: rgba(255,0,0,0.5); text-align:center;line-height:20px;color: #ffffff;
}
.upload_image_list .success{
  position: absolute; cursor:pointer;top: 0;right: 20px; width: 20px; line-height: 20px; color: green;
}
.upload_image_list:hover .delete{
  display: block;
}
.el-row{
  margin-bottom: 5px;
}
.el-row .el-col{
  padding-right: 5px;
}
.showVideo{
  width:200px; min-height: 200px; position: relative;
}
.showVideo .progress{
  position: absolute;top: 0;left: 0; width: 100%; height: 100%;
}
</style>